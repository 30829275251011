import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box } from '@material-ui/core';
import Link from '../Link';
import { Woxo } from '../Icon';
import translate from '../../data/translations';

import ExpandedMenu from '../ExpandedMenu';
import dynamic from 'next/dynamic';

const useStyles = makeStyles(theme => ({
  toolbar: {
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    '--padding': '15px',
    padding: '0px var(--padding)',
    '@media only screen and (min-width: 965px)': {
      '--padding': '30px'
    },
    '@media only screen and (min-width: 1280px)': {
      '--padding': '56px'
    }
  },
  appBar: {
    boxShadow: 'none',
    '@media only screen and (min-width: 768px)': {
      boxShadow: '0px 3px 6px #00000005'
    },
    backgroundColor: '#fff'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  avatar: {
    width: 'auto',
    borderRadius: '0px !important',
    height: 30,
    marginRight: '20px'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1,
    '@media only screen and (max-width: 768px)': {
      display: 'none'
    }
  },
  homeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10%',
    '@media only screen and (max-width: 1280px)': {
      marginRight: 24
    },
    '@media only screen and (max-width: 1024px)': {
      marginRight: 24
    },
    '@media only screen and (max-width: 320px)': {
      marginLeft: 0
    }
  },
  menuLink: {
    fontSize: 14,
    position: 'relative',
    letterSpacing: '1.4px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '19px',
    color: '#414042',
    textDecoration: 'none',
    // textTransform: 'uppercase',
    marginRight: '5%',
    transition: 'all 0.2s ease-in-out',
    '@media only screen and (max-width: 1024px)': {
      marginRight: '3%'
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: '1px',
      margin: '5px 0 0',
      opacity: 0,
      backgroundColor: '#414042',
      transition: 'all 0.2s ease-in-out',
      transitionDuration: '0.35s',
      left: '50%'
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: '1px',
      margin: '5px 0 0',
      opacity: 0,
      backgroundColor: '#414042',
      transition: 'all 0.2s ease-in-out',
      transitionDuration: '0.35s',
      right: '50%'
    },
    '&:hover:before': {
      left: '50%',
      width: '50%',
      opacity: 1
    },
    '&:hover:after': {
      right: '50%',
      width: '50%',
      opacity: 1
    }
  },
  menuContainer: {
    width: '100%',
    '@media only screen and (max-width: 960px)': {
      width: 0
    }
  },
  menuDesktopContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  activeLink: {
    color: '#0086FF',
    '&:before': {
      backgroundColor: '#0086FF'
    },
    '&:after': {
      backgroundColor: '#0086FF'
    }
  }
}));

// Render this menu with user information only client side to avoid hydration mismatches
const LinksSection = dynamic(() => import('./LinksSection'), { ssr: false });

const MainAppBar = ({
  position,
  onSignUp,
  onLogin,
  lang,
  children,
  className,
  showSignUp,
  showLogin,
  signUpLabel,
  showUserMenu,
  endContent,
  showLinks
}) => {
  const classes = useStyles();

  return (
    <AppBar position={position} className={clsx(classes.appBar, className)} color="inherit">
      <Toolbar className={classes.toolbar}>
        {children === null && (
          <>
            <div className={classes.homeContainer}>
              <Link href="/dashboard">
                <Woxo x="0px" y="0px" viewBox="0 0 185.3 59.8" />
              </Link>
            </div>
            {showLinks && (
              <div className={classes.menuContainer}>
                <Box className={classes.sectionDesktop}>
                  <ExpandedMenu type={'video'} />
                  <ExpandedMenu type={'widget'} />

                  <Link
                    href="/plans"
                    activeClassName={classes.activeLink}
                    className={classes.menuLink}
                  >
                    {translate('plans', lang, 'Plans')}
                  </Link>
                  <Link
                    href="/about"
                    activeClassName={classes.activeLink}
                    className={classes.menuLink}
                  >
                    {translate('aboutUs', lang, 'About us')}
                  </Link>
                </Box>
              </div>
            )}
          </>
        )}
        {children}
        {showLinks && (
          <Box display="flex" alignItems="baseline" flex="0 0 auto">
            <LinksSection
              onSignUp={onSignUp}
              onLogin={onLogin}
              showSignUp={showSignUp}
              showLogin={showLogin}
              signUpLabel={signUpLabel}
              showUserMenu={showUserMenu}
            />
            {endContent}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

MainAppBar.propTypes = {
  position: PropTypes.string,
  className: PropTypes.string,
  onSignUp: PropTypes.func,
  onLogin: PropTypes.func,
  lang: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  endContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  showSignUp: PropTypes.bool,
  showLogin: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  signUpLabel: PropTypes.string,
  showNotificationPanel: PropTypes.bool,
  showTutorials: PropTypes.bool,
  showLinks: PropTypes.bool
};

MainAppBar.defaultProps = {
  position: 'fixed',
  onSignUp: () => {},
  onLogin: undefined,
  lang: '',
  children: null,
  endContent: null,
  className: null,
  showSignUp: true,
  showLogin: true,
  showLanguageMenu: true,
  showUserMenu: true,
  signUpLabel: null,
  showNotificationPanel: true,
  showTutorials: true,
  showLinks: true
};

export default MainAppBar;
